import React from 'react';
import '../bio/bio.scss';
import backgroundImage from '../../assets/images/greg_hs.jpg';
import Banner from '../banner/banner';
import gregjanda from '../../assets/images/janda2016logo.jpg';
import Footer from '../footer/footer';

const Bio = () => {
    return (
        <>
         <Banner 
                topImg={gregjanda} 
                hasEndorsement={true}
                content={" Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2020. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council."} 
            />
        <div className="page-content-container">
            <div className="bio-content">
                <div className="bio" >
                    <div className='bio-img-text-wrapper'>


                        <div className='content-text'>
                            <h2>Greg’s Bio</h2>
                            <div className="bio-img-wrapper">
                                <img src={backgroundImage} />
                            </div>

                            <p>
                                Greg is a life-long Californian, moving to Rocklin in 2003. He immediately got involved in the community by participating in the second Leadership Rocklin, class of 2005, and continued as a steering committee member for Leadership Rocklin from 2006-2012.
                            </p>
                            <p>
                                Greg was elected to the City Council in 2012 as the top vote getter out of eight candidates. Greg was re-elected to the Council in 2016 and 2020. He is currently serving his third term as Mayor of Rocklin.
                            </p>
                            <p>
                                Greg’s professional career spans 37 years in the technology sector, owning and operating his own engineering design firm for 19 years.
                            </p>
                            <p>
                                Recently Greg switched careers and is now the controller for a Rocklin-based landscape company.
                            </p>
                            <p>
                                In addition to his role on the Council, Greg represents the city on a number of boards and committees. His current assignments include:
                            </p>
                            <ul>
                                <li>Chair, Placer County Air Pollution Control District</li>
                                <li>Boardmember, Pioneer Community Energy</li>
                                <li>Boardmember, Greater Sacramento Economic Council</li>
                                <li>Member of the Mayor’s committee with the cities of Lincoln</li>
                            </ul>
                            <p>
                                Greg’s hobbies include road biking, hiking, cooking, playing tennis and boating. He and his wife Kristin also spend time exploring the western U.S. towing their Airstream trailer.
                            </p>
                          
                        </div>


                    </div>


                </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Bio;
