import React, { useState } from 'react';
import './contact.scss';
import ContactModal from '../contact/contact-model';
import gregjanda from '../../assets/images/janda2016logo.jpg';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import Banner from '../banner/banner';
import Footer from '../footer/footer';

const Contact = ({ hasEndorseBar }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleEmailClick = () => {
    setIsContactModalOpen(true);
  };

  return (
    <>
      <Banner
        topImg={gregjanda}
        hasContactUs={true}
        content={"Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2020. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council."}
      />

      <div className="page-content-container">
        <div className="contact-page">
          <section>
            <div className="section-header">
              <h2>Contact Us</h2>
              <p>If you would like further information or have specific questions not addressed on this site, I would welcome the opportunity to speak with you.
                Please feel free to call me at 916-878-1532 or e-mail me at <a href="#" onClick={handleEmailClick}>Greg.Janda@gmail.com</a></p>
            </div>
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <HomeIcon fontSize='large' />
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>4500 Pacific St. suite O<br /> Rocklin, CA<br />95677</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <PhoneIcon fontSize='large' />
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>916-878-1532</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <EmailIcon fontSize='large' />
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <a href="#" onClick={handleEmailClick}>Greg.Janda@gmail.com</a>
                </div>
              </div>
            </div>
          </section>
          <section className='social-section-wrapper'>
            <h3>Join Us</h3>

            <div className="social-menu">
              <ul>
                <li><a href="http://www.linkedin.com/in/greg-janda-rocklin" target="_blank" rel="noopener noreferrer"><LinkedInIcon fontSize='large' /></a></li>
                <li><a href="https://x.com/Janda4Rocklin" target="_blank" rel="noopener noreferrer"><TwitterIcon fontSize='large' /></a></li>
                <li><a href="https://www.facebook.com/GregJandaForRocklinCityCouncil2024" target="_blank" rel="noopener noreferrer"><FacebookIcon fontSize='large' /></a></li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <Footer />
      <ContactModal open={isContactModalOpen} handleClose={() => setIsContactModalOpen(false)} />
    </>
  );
}

export default Contact;
