import React, { useState } from "react";
import "./header.scss";
import logo from '../../assets/images/GJ.png'; 
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const OceanHeader = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
  const topNavigation = [
    {
      label: "Home",
      value: "home",
      url: "/",
      active: (location.pathname === "/home" || location.pathname === "/"),
    },
    {
      label: "About",
      value: "about",
      url: "/about",
      active: location.pathname === "/about",
    },
    {
      label: "Accomplishments",
      value: "accomplishments",
      url: "/accomplishments",
      active: location.pathname === "/accomplishments",
    }, 
    {
      label: "Priorities",
      value: "priorities",
      url: "/priorities",
      active: location.pathname === "/priorities",
    },
    {
      label: "Help Greg",
      value: "contact",
      url: "/contact",
      active: location.pathname === "/contact",
    },
    {
      label: "More",
      active: (location.pathname === "/endorsements" ||  location.pathname === "/bio"),
      subNav: [{
        label: "Endorsements",
        value: "/endorsements",
        url: "/endorsements",
        active: location.pathname === "/endorsements",
      },
       {
        label: "Bio",
        value: "bio",
        url: "/bio",
        active: location.pathname === "/bio",
      },
    ]
    },
  ];

  const [showMenuItems, setShowMenuItems] = useState(false);
  const headerNavigation = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/${url}`, { state: { stoneId: "001", name: "sabaoon" } });
    setShowMenuItems(false);
  };
const handleClick = (e,url,hasSubMenu) => {
  e.preventDefault();
  if(hasSubMenu){
    return;
  }
  e.stopPropagation();
  setShowMenuItems(false);
  navigate(url);
};
  const onMainMenuClick = (e) =>{
        if( e.currentTarget.parentElement.classList.contains('expand')){
            e.currentTarget.parentElement.classList.remove('expand')
        }else{
            e.currentTarget.parentElement.classList.add('expand')
        }
  }
  return (
    <header className="ocean-header">
      <div className="img-wrap">
        <Link to={"/"}>
          <img title="American Headstone Granites" src={logo} alt="American Headstone Granites" />
          <span className="site-name">Greg Janda</span>
        </Link>
      </div>
      <div className="flex flex-v-center desktop circle">
        <ul className="flex nav-wrapper">
          {topNavigation.map((item, key) => {
            return (<>
              {(<li key={key} className={`${item.active ? "nav-active" : ""}`}>
                {item.url ? <Link to={item.url}>{item.label}</Link> : (<React.Fragment key={key}>
                  <Link to={item.url} onClick={() => setShowMenuItems(false)}>{item.label}</Link> 
                  <ul>
                    {item.subNav.map((childitem, childkey) => {
                      return(
                        <li key={childkey} className={`${childitem.active ? "nav-active" : ""}`}>
                          <Link to={childitem.url}>{childitem.label}</Link>
                        </li>
                      )
                    })}
                 </ul></React.Fragment>)}
              </li>)}
              </>);
          })}
        </ul>
       
      </div>
      <div className="flex flex-v-center mobile">
        <div
          className="menu"
          onClick={() => {
            setShowMenuItems(!showMenuItems);
          }}
        >
          <MenuIcon fontSize="large" />
        </div>
        {showMenuItems ? (
          <div className="side-drawer">
            <div class="close-icon-wrapper">
              <div className="profile-welcome">
                <span className="user-name">
                
                </span>
              </div>
              <span
                onClick={() => {
                  setShowMenuItems(!showMenuItems);
                }}
              >
                <CloseIcon fontSize="large" />
              </span>
            </div>
            <div className="side-menu-container">
              <ul className="flex nav-wrapper">
                {topNavigation.map((item, key) => {
                  return (<React.Fragment key={key}>
                    {item?.showDesktop !== true ?(<li className={`${item.active ? "nav-active" : ""}`}>
                      <span className="submenu-mobile" onClick={onMainMenuClick}>
                        <a href="#" onClick={(e)=>{handleClick(e,item.url,item?.subNav)}}>
                              {item.label}
                        </a>
                         {item?.subNav && (<><ArrowDropDownIcon fontSize="large" /></>)}
                         </span>
                      <ul className="mobile-child">
                        {item?.subNav?.map((childitem, childkey) => {
                        return(
                            <li key={childkey} className={`${childitem.active ? "nav-active" : ""}`}>
                               <a href="#" onClick={(e)=>{handleClick(e,childitem.url)}}>
                               {childitem.label}
                              </a>
                            </li>
                        )
                        })}
                    </ul>
                    </li>):null}
                  </React.Fragment>);
                })}
               
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  );
});

export default OceanHeader;
