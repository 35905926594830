// src/components/Accomplishments.js
import React from 'react';
import '../accomplishments/accomplishments.scss';
import Priorities from '../priorities/priorities';
import Footer from '../footer/footer';

const Accomplishments = () => {
    return (
        <>

            <Priorities accomplishmentsData={true} hasDonate={true}/>
           

        </>
    );
};

export default Accomplishments;
