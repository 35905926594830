import React, { useState, useEffect } from 'react';
import Banner from '../banner/banner';
import './home.scss';
import gregjanda from '../../assets/images/janda2016logo.jpg';
import gregJandaPhoto from '../../assets/images/greg_hs.jpg'; // Update with your image path
import ContactNoBanner from '../contact/contact_nobanner';
import ContactModal from '../contact/contact-model'; // Import the ContactModal component
import Footer from '../footer/footer';

const Home = () => {
    const [buttonColor, setButtonColor] = useState('teal'); // Initial button color
    const [isContactModalOpen, setIsContactModalOpen] = useState(false); // State for contact modal

    useEffect(() => {
        const colors = ['#ADE8F4', '#00ff00', '#0000ff', '#ffa500']; // List of colors
        let index = 0; // Initial color index

        const intervalId = setInterval(() => {
            // Change button color every second
            setButtonColor(colors[index]);
            index = (index + 1) % colors.length; // Move to the next color
        }, 1000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Run effect only once on component mount

    const handleEmailClick = (e) => {
        e.preventDefault(); // Prevent default mailto action
        setIsContactModalOpen(true); // Open the contact modal
    };

    return (
        <>
            <Banner
                topImg={gregjanda}
                hasDonate={true}
                content={"Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2020. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council."}
            />
            <div className="page-content-container">
                <div className="home-content">
                    <div className="content-with-photo">
                        <img src={gregJandaPhoto} alt="Greg Janda" className="left-photo" />
                        <div className="main-content">
                            <p>
                                Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2024. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council.
                            </p><br /><br />
                            <p>
                                Rocklin has much to be proud of – recognized regionally and nationally as an outstanding, family-friendly community but we have room for improvement. Like many families, the City is learning that it has limited, discretionary funds and must focus its spending on its top priorities.
                            </p><br /><br />
                            <p>
                                Now more than ever, public safety and economic development are the keys to sustainability. As a business owner / manager for the last 27 years, I’ve persevered through several economic cycles. I will continue to bring a business perspective and financial skills to the City Council. My experience has contributed to the conservative financial management of public funds these past twelve years. As a result, Rocklin has balanced budgets and increased public safety funding. Rocklin recently received the highest possible credit rating of AAA by Fitch. I will continue the practice of prudent tax dollar management – and continue to attract more businesses to our community. I am proud to have the support of many business, education, civic and public safety leaders, as well as neighbors and friends from throughout the community.
                            </p><br /><br />
                            <p>
                                I respectfully ask for your support.
                            </p><br /><br />
                            <p>
                                If re-elected, I will always do my best to do what’s right for our entire community and remember that I represent all Rocklin residents on the Council. I hope you find the information on this website useful in getting to know me and understanding how I would serve the City on the Council.
                            </p><br /><br />
                            <p>
                                If you would like further information or have specific questions not addressed on this site, I would welcome the opportunity to speak with you. Please feel free to email me at <a href="#" onClick={handleEmailClick}>Greg.Janda@gmail.com</a> or call me at <a href="tel:+19168781532">916-878-1532</a>.
                            </p><br /><br />
                        </div>
                    </div>
                    <center>
                        <div className='Divider'></div>
                    </center>
                    <ContactNoBanner hasEndorseBar={false} />
                </div>
            </div>
            <Footer/>
            <ContactModal open={isContactModalOpen} handleClose={() => setIsContactModalOpen(false)} /> {/* Contact modal */}
        </>
    );
}

export default Home;
