// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/about';
import Contact from './components/contact/contact';
import "./css/global.css";
import "./css/font.css";
import "./css/scroll.css";
import Accomplishments from './components/accomplishments/accomplishments';
import Endorsements from './components/endorsement/endorsements';
import Priorities from './components/priorities/priorities'
import Bio from './components/bio/bio';

const App = () => {
    return (
        <Router>
            <div>
                <Header />
                <div className="content">
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/accomplishments" element={<Accomplishments />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/endorsements" element={<Endorsements />} />
                        <Route path="/bio" element={<Bio />} />
                        <Route path="/priorities" element={<Priorities/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
