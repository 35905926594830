import React from 'react';
import './priorities.scss';
import Banner from '../banner/banner';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import gregjanda from '../../assets/images/janda2016logo.jpg';

import Footer from '../footer/footer';


const LeftPortion = styled('div')(({ theme }) => ({
    backgroundColor: grey[300],
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

const RightPortion = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}));

const FullHeightCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    padding: 0,
    '&:last-child': {
        paddingBottom: 0,
    },
}));

const cardData = [
    {
        title: "Attracting Businesses to Rocklin",
        content: "The City must use innovative approaches to attract new businesses to our community, including filling the vacant retail and office space along the Interstate 80 and Highway 65 corridors, as well as revitalizing our downtown. I will work to ensure the City supports the business community, rather than discouraging businesses with excessive regulation."
    },
    {
        title: "Responsible Spending By City Government",
        content: "With families and businesses facing economic struggles in our community, the City must use taxpayers’ dollars wisely at all times. The City should make detailed information available on its website about City spending, including salaries of its elected officials and top executives. I will balance budgets based on the priorities of Rocklin residents."
    },
    {
        title: "No Tax Increases",
        content: "Very simply, Rocklin taxpayers facing economic hardships today shouldn’t be asked to dig into their pockets for more tax money. As a Councilmember, I will work to deliver necessary services within available tax dollars."
    },
    {
        title: "Protect Public Safety",
        content: "As a Councilmember, I will remain focused on funding police and fire services – two key responsibilities of local government."
    },
    {
        title: "Supporting Our Parks",
        content: "Rocklin is a great community for families. Our system of parks is one reason families choose Rocklin and we need to ensure that our parks are properly maintained."
    },
    {
        title: "Partnering with Schools and Higher Education",
        content: "I will work closely with leaders of all schools in Rocklin. In addition to our excellent public schools, we are fortunate to have two of South Placer’s primary higher education institutions in our community – Sierra College and William Jessup University. I will work with educators at all levels to help them succeed and to partner with civic and business leaders."
    },
    // Add more cards as needed
];

const cardDataPriorities = [
    {
        title: "Led Opposition to a 17% Rate Increase by Pioneer Community Energy",
        content: "As a founding board member of Pioneer Community Energy, I have been an advocate for lower and more stable electricity rates. When Pioneer staff proposed a 17% rate increase, I led the board opposition, resulting in a much smaller rate increase while still accomplishing Pioneer’s goals. Since Pioneer’s inception in February 2018, Rocklin residents have saved more than $9 million in electricity costs and in excess of $85 million across all customers. This is money that goes directly back to our communities to help support Rocklin businesses and services."
    },
    {
        title: "COVID-19 Leadership",
        content: "As Rocklin Mayor during COVID-19, I initiated regional cooperation between Placer County leaders and Mayors to lobby against Governor Newsom’s “one-size-fits-all” approach to controlling the spread. As a result, Placer County was one of the first California counties to receive a local exception that allowed Rocklin businesses to re-open safely. In addition, I worked to assist struggling Rocklin businesses by waiving regulations and fees and providing access to federal funds to assist them through this time."
    },
    {
        title: "Balanced Budgets resulting in a AAA Bond Rating",
        content: "Rocklin is one of just seven cities in all of California to receive a AAA bond rating, lowering costs for every citizen. I’ve supported conservative financial management practices which have reduced our retirement liabilities, increased funds for road maintenance and resulted in an annual budget surplus."
    },
    {
        title: "Increased Public Safety Staffing",
        content: "COVID-19 has made public safety even more critical. During the economic downturn, Rocklin was faced with staff cuts in order to preserve essential city services. Throughout my twelve years on the Council, I’ve supported balanced budgets and annual increases in Public Safety Funding, including our recent budget passed in June."
    },
    {
        title: "Eliminated the Business License Tax",
        content: "Rocklin businesses were paying an annual business license fee tied to revenues. Some of the largest businesses in Rocklin were paying well over $100,000 annually simply because they were located in Rocklin. During my first two years in office we phased out this unfair tax. The most any single business will pay for an annual license is capped at $150 regardless of the size of the business. This not only saved our existing businesses hundreds of thousands of dollars per year, it allowed new businesses to locate in Rocklin. As a result, Rocklin’s sales tax receipts have averaged 17% annual growth during my twelve years on the council. The highest in Placer County."
    },
    {
        title: "Transparency",
        content: "During my term on the council, we have made several strides toward improving transparency to our citizens. We have gone above and beyond the minimum state law requirements on noticing and posting proposed projects. We dramatically increased the size of signage used to notice public hearings on proposed projects. All projects submitted to city staff are published on the city website within a few days, months before a scheduled public hearing. This provides our citizens with the same amount of review time as the city staff. State law requires as little as a 72 hour notice. We continue to expand the use of OpenGov for Rocklin’s Financial Transparency."
    },
    {
        title: "Construction of the Whitney Ranch / Highway 65 Interchange",
        content: "With no state or federal funds available, we were forced to come up with creative solutions to open additional access into Whitney Ranch. Working closely with our private property owners, we were able to fully fund and pay for the construction of the Hwy 65 / Whitney Ranch interchange. This project is debt free and will serve as the beginning of the Placer Parkway that will eventually connect Hwy 65 with Hwy 70 and the Sacramento airport."
    },
    {
        title: "Redevelopment of Downtown and Creation of Quarry Park",
        content: "In an effort to re-ignite activity in downtown Rocklin, we capitalized on our unique mining history and created the Quarry District. At the center is Quarry Park with its ponds, oak trees, walking trails, 1,200 seat amphitheater and adventure park. These unique attractions to our downtown, unmatched in our region, helps drive economic activity to Rocklin, creating jobs and revenue that is reinvested into our city to help us meet our sustainability goals."
    },
]

const Priorities = ({accomplishmentsData}) => {
    return (
        <>
        <div>
            <Banner 
                topImg={gregjanda} 
                hasDonate={true} 
                content={" Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2020. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council."} 
            />

            <div className="page-content-container-priorities">
                {(cardData && !accomplishmentsData) && cardData.map((card, index) => (
                    <Card key={index} style={{ marginBottom: '16px' }}>
                        <FullHeightCardContent>
                            <Grid container style={{ height: '100%' }}>
                                <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
                                    <LeftPortion>
                                        <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', fontFamily: 'Montserrat' }}>
                                            {card.title}
                                        </Typography>
                                    </LeftPortion>
                                </Grid>
                                <Grid item xs={12} sm={8} style={{ display: 'flex' }}>
                                    <RightPortion>
                                        <Typography variant="h7" style={{lineHeight:'28px'}}>
                                            {card.content}
                                        </Typography>
                                    </RightPortion>
                                </Grid>
                            </Grid>
                        </FullHeightCardContent>
                    </Card>
                ))}
            </div>
                {accomplishmentsData && (<>
                    <div className="page-content-container-priorities">
                    {cardDataPriorities.map((card, index) => (
                        <Card key={index} style={{ marginBottom: '16px' }}>
                            <FullHeightCardContent>
                                <Grid container style={{ height: '100%' }}>
                                    <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
                                        <LeftPortion>
                                            <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', fontFamily: 'Montserrat' }}>
                                                {card.title}
                                            </Typography>
                                        </LeftPortion>
                                    </Grid>
                                    <Grid item xs={12} sm={8} style={{ display: 'flex' }}>
                                        <RightPortion>
                                            <Typography variant="h7" style={{lineHeight:'28px'}}>
                                                {card.content}
                                            </Typography>
                                        </RightPortion>
                                    </Grid>
                                </Grid>
                            </FullHeightCardContent>
                        </Card>
                    ))}
                </div>
                </>
                )}
        </div>
         <Footer/>
        </>

    );
};
export default Priorities;
