import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControlLabel, Checkbox, Grid, Typography } from '@mui/material';

const SERVICE_ID = 'service_2tuvk7n'; // Replace with your EmailJS service ID
const TEMPLATE_ID = 'template_un1hdbt'; // Replace with your EmailJS template ID
const PUBLIC_KEY = 'V0nzQpfMDrAJlP4fU'; // Replace with your EmailJS public key

const Form = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    title: '',
    address1: '',
    address2: '',
    city: '',
    state: 'California',
    zip: '',
    email: '',
    confirmEmail: '',
    phone: '', yardSign: 'No', hostCoffee: 'No', handoutFlyers: 'No' 
  });

  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 'Yes' : 'No') : value
    setFormData(prevData => ({
      ...prevData,
      [id]: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      title: formData.title,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      email: formData.email,
      phone: formData.phone,
      yardSign: formData.yardSign, 
      hostCoffee: formData.hostCoffee, 
      handoutFlyers: formData.handoutFlyers,
    };

    try {
      await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        templateParams,
        PUBLIC_KEY
      );
      setFormData({
        firstName: '',
        lastName: '',
        title: '',
        address1: '',
        address2: '',
        city: '',
        state: 'California',
        zip: '',
        email: '',
        confirmEmail: '',
        phone: '', yardSign: 'No', hostCoffee: 'No', handoutFlyers: 'No' 
      });
      handleClose();
    } catch (error) {
      alert('An error occurred: ' + error.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Endorse Greg Janda</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                id="firstName"
                label="First Name"
                type="text"
                fullWidth
                variant="outlined"
                required
                onChange={handleChange}
                value={formData.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="lastName"
                label="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                required
                onChange={handleChange}
                value={formData.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="title"
                label="Title"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={formData.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="address1"
                label="Street Address"
                type="text"
                fullWidth
                variant="outlined"
                required
                onChange={handleChange}
                value={formData.address1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="address2"
                label="Address Line 2"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={formData.address2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="city"
                label="City"
                type="text"
                fullWidth
                variant="outlined"
                required
                onChange={handleChange}
                value={formData.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="state"
                label="State"
                type="text"
                fullWidth
                variant="outlined"
                value="California"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="zip"
                label="ZIP Code"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                required
                value={formData.zip}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="email"
                label="Enter Email"
                type="email"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                required
                value={formData.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="phone"
                label="Phone"
                type="tel"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                required
                value={formData.phone}
              />
            </Grid>
            <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox id="yardSign" onChange={handleChange} checked={formData.yardSign === 'Yes'} />}
                                label="Request a Yard Sign"
                            />
                           
                            <FormControlLabel
                                control={<Checkbox id="hostCoffee" onChange={handleChange} checked={formData.hostCoffee === 'Yes'} />}
                                label="Host a neighborhood coffee"
                            />
                            <FormControlLabel
                                control={<Checkbox id="handoutFlyers" onChange={handleChange} checked={formData.handoutFlyers === 'Yes'} />}
                                label="Hand out flyers to your neighbors"
                            />
                        </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
