// src/components/Contact.js
import React from 'react';
import './footer.scss'

const Footer = () => {
  return (
    <>
    
      <div className="page-footer-container">
      <p className="footer-text">&#169; Paid for by Friends of Greg Janda for Rocklin City Council 2024  FPPC #1469921</p>
      </div>
    </>
  )
}

export default Footer;
