// src/components/About.js
import React from 'react';
import './about.scss';
import Banner from '../banner/banner';
import GregJandaImage from '../../assets/images/gregJanda1w.jpg'; // Adjust the path to your image
import GregJandaFamilyImage from '../../assets/images/greg-family.jpeg'; // Adjust the path to your image
import gregjanda from '../../assets/images/janda2016logo.jpg'
import DetailCard from '../detailcard/detailcard';
import Footer from '../footer/footer';



const About = () => {
    return (
        <>
            <Banner topImg={gregjanda} hasDonate={true} content={" Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2020. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council."} />
            <div className="page-content-container">
                <div className="about-page-container">
                    <DetailCard img={GregJandaFamilyImage} imgPosition="left"  content={
                        <div>
                            <p>
                                Greg Janda is a lifelong Californian who, along with his wife, Kristin, and two daughters moved to Rocklin seventeen years ago. Greg spent thirty-seven years in the technology sector, the last nineteen owning his own engineering design company. Today, Greg is the business manager of a Rocklin based specialty landscape company.
                            </p>
                            <p>
                                Greg holds a bachelor’s degree in business from San Jose State University and earned his degree while working full time.
                            </p>
                            <p>
                                Since moving to Rocklin, Greg has been involved in the community in a number of ways, including volunteering at his church, at school and sports activities involving his children and in programs run by the Rocklin Area Chamber of Commerce’s Leadership Rocklin Program.
                            </p>
                            <p>
                                Greg completed the Chamber’s Leadership Rocklin Program in 2005 and continued serving as a member of the Steering Committee that has administered the program. During that time, over 140 aspiring leaders have graduated from the program and gained insight into local, regional and state civic and government organizations.
                            </p>
                        </div>
                        }>
                    </DetailCard>
                    <DetailCard img={GregJandaImage} imgPosition="right" cardTitle={"More about Greg"} content={
    <div className="list-with-checkmarks">
        <ul>
            <li>Rocklin Mayor 2024, 2020, 2016  </li>
            <li>Rocklin City Councilmember 2012 – Present</li>
            <li>Represents Rocklin on a number of Boards and Commissions</li>
            <li>Greg and his wife, Kristin, have two children who grew up attending Rocklin schools.</li>
            <li>Small business owner, 19 years</li>
            <li>Business degree, San Jose State University</li>
            <li>2005 Graduate of the Rocklin Chamber of Commerce Leadership Rocklin Program</li>
            <li>2011 Graduate of the Rocklin Citizens Academy</li>
            <li>Steering Committee, Rocklin Area Chamber of Commerce Leadership Rocklin Program, 2005 – 2012</li>
            <li>Feed My Starving Children Steering Committee, 2011-2012</li>
            <li>Other current and past volunteer activities: Rocklin Elementary School, Rocklin Wave Swim Team, Hot Chili & Cool Cars and Scramble for Scholars Committees</li>
            <li>Member, annual church mission team to Eastern Africa, 2006, 2007, 2009, 2010, 2011, 2014, 2016</li>
        </ul>
    </div>
} />

                </div>

            </div>
            <Footer/>
        </>
    );
};

export default About;
