import React, { useState, useEffect } from 'react';
import '../banner/banner.scss';
import bannerImage3 from '../../assets/images/download.jpg';
import bannerImage1 from '../../assets/images/train.jpg';
import EndorsementModal from '../endorsement/endorsement-model';
import bannerImage2 from '../../assets/images/qp.jpg';
import bannerImage4 from '../../assets/images/amp.jpg';
import ContactModal from '../contact/contact-model';

const images = [bannerImage1, bannerImage2, bannerImage3, bannerImage4];

const Banner = ({ topImg, title, subtitle, hasDonate, hasEndorsement, hasContactUs }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEndorsementModalOpen, setIsEndorsementModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  // Preload images and start the interval for changing images
  useEffect(() => {
    const preloadImages = () => {
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    };

    preloadImages();

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  const currentImage = images[currentImageIndex];

  return (
    <>
      <div
        className="banner-new-outer-wrapper"
        style={{
          backgroundImage: `url(${currentImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: 'auto',
          overflow: 'hidden'
        }}
      >
        <div className="banner-content-wrapper">
          <div className="banner-content-wrapper-inner">
            {topImg && <img src={topImg} className="gregjandaimg" alt="gregjanda img" />}
            <div className="banner-new-text">
              {title && <h1 className="banner-new-name">{title}</h1>}
              {subtitle && <h3 className="banner-new-name-council">{subtitle}</h3>}
            </div>
          </div>
        </div>
      </div>

      {hasDonate && (
        <div className="donate-header">
          <p>Help Greg Janda Continue to Serve Rocklin Residents</p>
          <button>
            <a href="https://www.efundraisingconnections.com/c/FriendsofGregJanda/" target="_blank" rel="noopener noreferrer">
              Donate
            </a>
          </button>
        </div>
      )}

      {hasEndorsement && (
        <div className="endorsement-header">
          <p>Show Your Support: Endorse Greg Janda Today!</p>
          <button onClick={() => setIsEndorsementModalOpen(true)}>Add your Name to the List</button>
        </div>
      )}

      <EndorsementModal open={isEndorsementModalOpen} handleClose={() => setIsEndorsementModalOpen(false)} />

      {hasContactUs && (
        <div className="endorsement-header">
          <p>Help Greg Janda</p>
          <button onClick={() => setIsContactModalOpen(true)}>Contact Greg</button>
        </div>
      )}

      <ContactModal open={isContactModalOpen} handleClose={() => setIsContactModalOpen(false)} />
    </>
  );
};

export default Banner;
