// src/components/Banner.js
import React, { useState, useEffect, useRef } from 'react';
import '../banner/banner.scss';
import bannerImage from '../../assets/images/MainBanner.jpg'; 
import EndorsementModal from './endorsement-model';
import ContactModal from '../contact/contact-model';
const Banner = ({topImg,title,subtitle,content,hasDonate, hasEndorsement, hasContactUs }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
      setModalOpen(true);
  };

  const handleCloseModal = () => {
      setModalOpen(false);
  };
  const [contactModalOpen, setContactModalOpen] = useState(false);

    const handleOpenContactModal = () => {
        setContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };
  return (<>
    <div
      className="banner-new-outer-wrapper"
      style={{
        backgroundImage: `url(${bannerImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center', // Adjust this value as needed
        height: 'auto', // Adjust height as needed
        overflow: 'hidden' // Ensure any overflow is hidden
      }}
    >
      <div className="banner-content-wrapper">
      <div className="banner-content-wrapper-inner">
        
        {topImg && <img src={topImg} className='gregjandaimg' alt='gregjanda img' />}
       
        <div className='banner-new-text'>
            {title && <h1 className="banner-new-name">{title}</h1>}
            {subtitle && <h3 className="banner-new-name-council">{subtitle}</h3>}
        {content && <p>
         {content}
        </p>}

      </div>
      </div>
      </div>
    </div>
    {hasDonate && (<div className="endorsement-header">
                <p>Help us continue our work by making a donation.</p>
                <button >Donate Now</button>
            </div>)}
            <div>
            {hasEndorsement && (
                <div className="endorsement-header">
                    <p>Endorse Greg Janda TODAY! </p>
                    <button onClick={handleOpenModal}>Add your Name to the List</button>
                </div>
            )}
            <EndorsementModal open={modalOpen} handleClose={handleCloseModal} />
        </div>
        <div>
            {hasContactUs && (
                <div className="endorsement-header">
                    <p>Contact Greg Janda</p>
                    <button onClick={handleOpenContactModal}>Contact Us</button>
                </div>
            )}
            <ContactModal open={contactModalOpen} handleClose={handleCloseContactModal} />
        </div>
  </>);
};

export default Banner;
