import React from 'react';
import './endorsement.scss';
import { Grid } from "@mui/material";
import gregjanda from '../../assets/images/janda2016logo.jpg';
import Banner from '../banner/banner';
import Footer from '../footer/footer';


const cardData = {
    "Community Leaders": [
        { title: "U.S. Congressman", name: "Kevin Kiley" },
        { title: "Placer County Supervisor", name: "Shanti Landon" },
        { title: "Placer County Supervisor", name: "Bonnie Gore" },
        { title: "Placer County Supervisor", name: "Jim Holmes" },
        { title: "Rocklin Vice Mayor", name: "Jill Gayaldo" },
        { title: "Rocklin City Councilmember", name: "Bill Halldin" },
        { title: "Rocklin City Councilmember", name: "Ken Broadway" },
        { title: "Rocklin City Councilmember", name: "David Bass" },
        { title: "Rocklin School Board Member", name: "Rachelle Price" },
        { title: "Former Rocklin Mayor", name: "Peter Hill" },
        { title: "Former Rocklin Mayor", name: "Scott Yuill" },
        { title: "Former Rocklin Mayor", name: "Diana Ruslin" },
        { title: "Former Rocklin Mayor", name: "Brett Storey" },
        { title: "Former Rocklin School Board Member", name: "Susan Halldin" },
        { title: "Former Rocklin School Board Member", name: "Wendy Lang" },
        { title: "Sierra College Trustee", name: "Carol Garcia" },
        { title: "Sierra College Trustee", name: "Bob Romness" }
    ],
    "Organizations": [
        { title: "Rocklin Firefighters Local #3847" },
        { title: "Rocklin Police Officers’ Association" },
        { title: "Rocklin Area Chamber of Commerce PAC" },
    ],
    "Rocklin Business Leaders": [
        { title: "Rocklin Business Owner", name: "Lisa Peters" },
        { title: "Rocklin Realtor", name: "Sonia Immers" },
        { title: "Rocklin Businesswoman", name: "Robin Trimble" },
        { title: "Rocklin Businesswoman", name: "Christy Jewell" },
        { title: "Rocklin Businessman", name: "Franklin Burriss" },
        { title: "Rocklin Businessman", name: "Mark Diele" },
        { title: "Rocklin Businessman", name: "Joe Cronin" },
        { title: "Rocklin Businessman", name: "Jay Lang" }
    ],
    "Rocklin Families": [
        { title: "Scott and Lisa Graves" },
        { title: "Greg and Amy Morris" },
        { title: "Ron and Leslie Domingo" },
        { title: "Dan and Carol Wilson" },
        { title: "Steve and Rachel Lund" },
        { title: "Dave and Debbie Ramos" }
    ]
};

const Endorsements = () => {
    return (
        <>
        
        
        <Banner
                topImg={gregjanda}
                hasEndorsement={true} 
                content={" Thank you for visiting my website and for taking the time to find out about my candidacy for Rocklin City Council 2020. I appreciate your interest and would be honored to continue serving the citizens of Rocklin as a member of our City Council."} 
            />
            <div className="page-content-container">
            <div className="en-page">
                <h1>Community Leaders and Supporters</h1>
                {Object.entries(cardData).map(([section, cards], idx) => (
                    <div className="en-section" key={idx}>
                        <h2>{section}</h2>
                        <div className="en-cards">
                            <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {cards.map((card, idx) => (
                                    <Grid item xs={4} sm={4} md={3} key={idx    }>
                                        <div className="en-card" key={idx}>
                                            <h3>{card.title}</h3>
                                            {card.name && <p>{card.name}</p>}
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Footer/>
        </>
    );
};

export default Endorsements;
