// src/components/About.js
import React from 'react';
import './detailcard.scss';
const DetailCard = ({ content, img, imgPosition, cardTitle }) => {
    return (
        <>
         <div className="detailcard-container-wrapper">
            {cardTitle && <h1>{cardTitle}</h1>}
            <div className={`detailcard-container ${imgPosition == 'right'?"img-right-position":""}`}>
                <img src={img} alt="Greg Janda" className="detailcard-image" />
                <div className="detailcard-text">                   
                    {content}
                </div>
            </div>
            </div>
        </>
    );
};

export default DetailCard;
