import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, FormControlLabel, Checkbox } from '@mui/material';
import emailjs from 'emailjs-com';

const ContactModal = ({ open, handleClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        endorse: 'No',
        yardSign: 'No',
        hostCoffee: 'No',
        handoutFlyers: 'No'
    });

    const handleChange = (e) => {
        const { id, type, checked, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: type === 'checkbox' ? (checked ? 'Yes' : 'No') : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Replace these with your actual EmailJS service ID, template ID, and public key
        const SERVICE_ID = 'service_2tuvk7n'; // Replace with your EmailJS service ID
        const TEMPLATE_ID = 'template_s3qhj73'; // Replace with your EmailJS template ID
        const PUBLIC_KEY = 'V0nzQpfMDrAJlP4fU'; // Replace with your EmailJS public key

        emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, PUBLIC_KEY)
            .then(() => {
                setFormData({ name: '', email: '', message: '', endorse: 'No', yardSign: 'No', hostCoffee: 'No', handoutFlyers: 'No' });
                handleClose();
            })
            .catch((error) => {
                console.error('Failed to send message:', error);
                alert('Failed to send message, please try again later.');
            });
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Contact Greg Janda</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                variant="outlined"
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="message"
                                label="Message"
                                type="text"
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                required
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox id="yardSign" onChange={handleChange} checked={formData.yardSign === 'Yes'} />}
                                label="Request a Yard Sign"
                            />
                            <FormControlLabel
                                control={<Checkbox id="endorse" onChange={handleChange} checked={formData.endorse === 'Yes'} />}
                                label="Endorse"
                            />
                            <FormControlLabel
                                control={<Checkbox id="hostCoffee" onChange={handleChange} checked={formData.hostCoffee === 'Yes'} />}
                                label="Host a neighborhood coffee"
                            />
                            <FormControlLabel
                                control={<Checkbox id="handoutFlyers" onChange={handleChange} checked={formData.handoutFlyers === 'Yes'} />}
                                label="Hand out flyers to your neighbors"
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ContactModal;
